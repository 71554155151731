<template>
  <div
    class="box mb-2 modulo azul-prioridades"
    v-bind:class="{ 'is-loading': isLoading }"
  >
    <div class="box mod mcabecera mctitulo azul-prioridades">Prioridades</div>
    <div class="box mod pt-0 mcontenido azul-prioridades pt-0">
      <div class="columns is-variable is-1">
        <div
          class="column is-one-third"
          v-for="(mdle, index) in products"
          :key="index"
        >
          <div class="box int-mod modulo">
            <div class="box mod mcontenido m-0 pb-0">
              <div class="content mb-0 mctitulo font-80p tazul pb-1">
                {{ mdle.name }}
              </div>
              <div class="content prio-img has-text-centered">
                <figure class="image is-fullwidth m-0 is-inline-block">
                  <img :src="'/assets/prioridades/' + mdle.image" />
                </figure>
              </div>
            </div>
            <div class="box mod mpie prds pie-prio">
              {{ mdle.description }}
            </div>
            <!-- <div class="box mod mcontenido m-0 sin-bpad mctitulo font-80p tazul" >
              {{ mdle.name }}
              <figure class="image is-fullwidth m-0">
                <img :src="mdle.image" />
              </figure>
            </div>
            <div :class="'box mod mpie prds ' + mdle.class">
              {{ mdle.description }}
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ this.$props }}</pre> -->
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";

export default {
  name: "Priorities",
  data() {
    return {
      products: [],
      selected_product: undefined,
      isLoading: false,
    };
  },
  props: {
    business: { type: Object, default: undefined },
    time: { type: Object, default: undefined },
  },
  methods: {
    update() {
      if (this.business && this.business.name && this.time && this.time.value) {
        let vm = this;
        vm.isLoading = true;
        post_request(vm, "/home/do-get-priorities", {
          business: this.business.business_id,
          time: this.time.value,
        })
          .then((response) => {
            vm.isLoading = false;
            this.products = response;
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
  },
  watch: {
    business() {
      this.update();
    },
    time() {
      this.update();
    },
  },
};
</script>
