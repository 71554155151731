<template>
  <div v-bind:class="{ 'is-loading': isLoading }">
    <div class="box modulo mb-4">
      <div class="box mod mprincipal verde-plaza">
        <div class="columns is-variable is-1 is-vcentered">
          <div class="column mctitulo">PLAZA SECUNDARIA</div>
          <div class="column is-three-fifths mctitulo has-text-centered">
            <dropdown
              title="Selecciona el PDV"
              @selected="pos_changed"
              :data="pos_dd"
            />
            <!-- <i class="fas fa-map-marker-alt"></i>&nbsp;008 Walmart Cuitláhuac -->
          </div>
          <div class="column is-1 mctitulo" @click="show_all()">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
    </div>
    <!--Items de plaza secundaria-->
    <div class="columns mb-1 is-variable is-1 is-vcentered is-multiline">
      <div
        class="column py-1 is-half"
        v-for="(prdct, idx) in products"
        :key="idx"
        @click="show_more(prdct)"
      >
        <div class="box ext-mod">
          <div class="columns is-vcentered">
            <div class="column py-0 is-4">
              <figure class="image is-fullwidth">
                <img :src="'/assets/planogramas/' + prdct.icon" />
              </figure>
            </div>
            <div class="column mctitulo tverde">{{ prdct.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ this.$props }}</pre> -->
    <secondary-place-more
      ref="secondary_place_more"
      :selectedItem="selected_item"
    />
    <secondary-place-all
      ref="secondary_place_all"
      :business="business"
      :brand="brand"
      :time="time"
    />
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";
import SecondaryPlaceMore from "./SecondaryPlaceMore";
import SecondaryPlaceAll from "./SecondaryPlaceAll";
import Dropdown from "@/components/Dropdown";
import { groupBy, orderBy } from "lodash";

export default {
  name: "SecondaryPlace",
  components: { SecondaryPlaceMore, SecondaryPlaceAll, Dropdown },
  data() {
    return {
      products: [],
      isLoading: false,
      selected_item: undefined,
      pos_dd: [],
      selected_pos: undefined,
    };
  },
  props: {
    business: { type: Object, default: undefined },
    brand: { type: Object, default: undefined },
    time: { type: Object, default: undefined },
  },
  methods: {
    show_more(product) {
      this.selected_item = product;
      this.$refs.secondary_place_more.show();
    },
    show_all() {
      if (this.$props.business && this.$props.business.business_id)
        this.$refs.secondary_place_all.show();
      else error_toast(this, "Selecciona el PDV");
    },
    pos_changed(pos) {
      // console.log("selected pos", pos);
      this.selected_pos = pos;
      this.update();
    },
    update() {
      let vm = this;
      if (vm.selected_pos && vm.time && vm.time.value) {
        vm.isLoading = true;
        post_request(vm, "/home/do-get-secondary-place", {
          business: this.business.business_id,
          pos: vm.selected_pos.customer_code,
          time: vm.time.value,
        })
          .then((response) => {
            vm.isLoading = false;

            this.products = response;
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
  },
  created() {
    // let vm = this;
    // post_request(vm, "/home/do-get-points-of-sale", {
    //   business: this.business ? this.business.name : "",
    // })
    //   .then((response) => {
    //     let groups = groupBy(response, (i) => {
    //       return i.state;
    //     });
    //     vm.pos_dd = Object.keys(groups).map((k) => {
    //       return { name: k, items: groups[k] };
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     error_toast(vm, err);
    //   });
  },
  watch: {
    business() {
      this.products = [];
      if (this.business && this.business.name) {
        let vm = this;
        vm.isLoading = true;
        post_request(vm, "/home/do-get-points-of-sale", {
          business: this.business.business_id,
        })
          .then((response) => {
            vm.isLoading = false;

            let replacements = [
              { original: "MEXICO", new: "Estado de México" },
              { original: "DISTRITO FEDERAL", new: "Ciudad de México" },
            ];
            let groups = groupBy(response, (i) => {
              let trgtReplacement = replacements.find(
                // eslint-disable-next-line prettier/prettier
                (repl) => repl.original == i.state,
              );
              if (trgtReplacement) return trgtReplacement.new;
              return i.state;
            });

            let list = Object.keys(groups).map((k) => {
              return { items: groups[k], name: k };
            });

            let ordered = orderBy(
              list,
              function (a) {
                return a.name;
              },
              // eslint-disable-next-line prettier/prettier
              ["asc"],
            );

            vm.pos_dd = ordered;

            // this.products = response;
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
    time() {
      this.update();
    },
  },
};
</script>

<style scoped>
/* .column.is-half .box :hover {
  background-color: #67a341;
} */
</style>
