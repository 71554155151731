<template>
  <div class="box modulo mb-2" v-bind:class="{ 'is-loading': isLoading }">
    <div class="box mod mcabecera violeta-promocion">
      <div class="columns is-variable is-1 is-vcentered">
        <div class="column mctitulo">Promoción</div>
        <div class="column mcsubtitulo has-text-right">KIP: Señalización.</div>
      </div>
    </div>
    <div class="box mod mcontenido m-0 pb-1">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th><abbr title="Descripción">Descripción</abbr></th>
            <th><abbr title="Actividad">Actividad</abbr></th>
            <th><abbr title="Vigencia">Vigencia</abbr></th>
            <th><abbr title="Más...">Más...</abbr></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(product, idx) in products"
            :key="idx"
            v-bind:class="{ novigente: product.is_invalid_by_date }"
          >
            <th>{{ product.description }}</th>
            <th>{{ product.activity }}</th>
            <th>{{ product.validity }}</th>
            <th class="ver-mas has-text-centered">
              <a @click="show_more(product)"><i class="far fa-eye"></i></a>
              <div class="box modulo tooltipup" v-if="product.is_showing_more">
                <div class="box mod has-text-centered mb-0">
                  <figure class="image is-fullwidth m-0 is-inline-block">
                    <img :src="product_image(product)" />
                  </figure>
                </div>
                <div class="box mod tooltip-desc has-text-left mb-0">
                  <p><strong>EAN: </strong>{{ product.ean_code }}</p>
                  <p>
                    <strong>Descripción: </strong>{{ product.description }}A
                  </p>
                  <p><strong>Actividad: </strong>{{ product.activity }}</p>
                  <p><strong>Vigencia: </strong>{{ product.validity }}</p>
                  <p v-show="product.scope">
                    <strong>Alcance: </strong>{{ product.scope }}
                  </p>
                </div>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box mod mpie pt-1">
      <pagination
        :current="current_page"
        :total="total_products"
        :itemsPerPage="4"
        :onChange="onPaginationChanged"
      >
      </pagination>
    </div>
    <!-- <pre>{{ this.$props }}</pre> -->
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";
import Pagination from "vue-2-bulma-pagination";
import { cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "Promotion",
  components: { Pagination },
  data() {
    return {
      products: [],
      all_products: [],
      isLoading: false,
      current_page: 1,
      total_products: 0,
    };
  },
  props: {
    business: { type: Object, default: undefined },
    time: { type: Object, default: undefined },
  },
  methods: {
    show_more(product) {
      let isShowingMore = product.is_showing_more;
      this.products.map((p) => (p.is_showing_more = false));
      product.is_showing_more = !isShowingMore;
      if (product.is_showing_more) this.$emit("popup");
      // this.selected_product = product;
      // this.$refs.product_price_more.show();
    },
    onPaginationChanged(page) {
      let idx = (page - 1) * 4;
      let prds = this.all_products.slice(idx, idx + 4);
      this.products = prds;
      this.current_page = page;
    },
    product_image(product) {
      let path = `/productos/${product.ean_code}.jpg`;
      var http = new XMLHttpRequest();
      http.open("HEAD", path, false);
      http.send();

      if (http.status == 404) {
        let brand_name =
          product && product.brand_name ? product.brand_name.toLowerCase() : "";
        return `/productos/default_${brand_name}.jpg`;
      } else return path;
    },
    update() {
      if (this.business && this.business.name && this.time && this.time.value) {
        let vm = this;
        vm.isLoading = true;
        post_request(vm, "/home/do-get-promotion", {
          business: this.business.business_id,
          time: this.time.value,
        })
          .then((response) => {
            vm.isLoading = false;
            this.all_products = response.map((r) => {
              r.is_showing_more = false;
              let endDate = r.end_date;
              if (endDate) {
                r.is_invalid_by_date = moment() > moment(endDate);
              }
              return r;
            });

            let elements_number = this.all_products.length;

            let modulus = elements_number % 4;
            if (modulus > 0) {
              let left_elements = 4 - modulus;
              if (left_elements > 0) {
                let copied = this.all_products.slice(0, left_elements);
                this.all_products = this.all_products.concat(cloneDeep(copied));
                this.total_products = this.all_products.length;
              }
            }

            this.total_products = this.all_products.length;

            this.onPaginationChanged(1);

            // this.products = this.all_products;
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
    hide_popups() {
      this.products.map((p) => (p.is_showing_more = false));
    },
  },
  watch: {
    business() {
      this.update();
    },
    time() {
      this.update();
    },
  },
};
</script>
