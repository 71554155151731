<template>
  <div
    class="columns is-variable is-1 is-vcentered is-multiline"
    v-bind:class="{ 'is-loading': isLoading }"
  >
    <div
      class="column py-1 is-one-quarter"
      v-for="(prdct, idx) in products"
      @click="show_more(prdct)"
      :key="idx"
    >
      <div class="box ext-mod">
        <div class="content px-2 mb-0">
          <figure class="image is-fullwidth m-0">
            <img :src="'/assets/material-pop/' + prdct.image_url" />
          </figure>
        </div>
        <div class="content mctitulo tverde has-text-centered">
          {{ prdct.short_name }}
        </div>
        <!-- <div class="content mctitulo tverde has-text-centered">
          <figure class="image is-fullwidth m-0">
            <img :src="prdct.image" />
          </figure>
          {{ prdct.short_name }}
        </div> -->
      </div>
    </div>
    <!-- <pre>{{ this.$props }}</pre> -->
    <pop-items-more ref="pop_items_more" :selectedItem="selected_item" />
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";
import PopItemsMore from "./PopItemsMore";

export default {
  name: "PopItems",
  components: { PopItemsMore },
  data() {
    return {
      products: [],
      isLoading: false,
      selected_item: undefined,
    };
  },
  props: {
    brand: { type: Object, default: undefined },
    time: { type: Object, default: undefined },
  },
  methods: {
    show_more(product) {
      this.selected_item = product;
      this.$refs.pop_items_more.show();
    },
    update() {
      if (this.brand && this.brand.name && this.time && this.time.value) {
        let vm = this;
        vm.isLoading = true;
        post_request(vm, "/home/do-get-pop-material", {
          brand: this.brand.brand_id,
          time: this.time.value,
        })
          .then((response) => {
            vm.isLoading = false;
            this.products = response;
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
  },
  watch: {
    brand() {
      this.update();
    },
    time() {
      this.update();
    },
  },
};
</script>

<style scoped>
/* .column.is-half .box :hover {
  background-color: #67a341;
} */
</style>
