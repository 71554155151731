<template>
  <div class="modal" v-bind:class="{ 'is-active': active }">
    <div class="modal-background" @click.stop="close"></div>
    <div class="modal-card">
      <header class="modal-card-head has-text-weight-semibold">
        {{ selectedItem ? selectedItem.short_name : "" }}
      </header>
      <section class="modal-card-body mpie has-text-centered">
        <figure class="modal-material-pop image is-fullwidth is-inline-block">
          <img :src="'/assets/material-pop/' + image" alt="" class="image" />
          <!-- <pre>
            {{ selectedItem }}
        </pre> -->
        </figure>
      </section>
      <!-- <footer class="modal-card-foot">
        <button class="button" @click="close">Cancel</button>
      </footer> -->
    </div>
    <button
      class="modal-close is-large delete"
      aria-label="close"
      @click="close"
    ></button>
  </div>
</template>

<script>
import starCase from "lodash";

export default {
  name: "PopItemsMore",
  props: {
    selectedItem: Object,
  },
  filters: {
    str: starCase,
  },
  computed: {
    image() {
      if (this.selectedItem) {
        return this.selectedItem.image_url || "default_image.jpg";
      } else return "default_image.jpg";
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    close() {
      this.active = false;
    },
    show() {
      this.active = true;
    },
  },
  mounted() {},
};
</script>
