<template>
  <div class="" v-bind:class="{ 'is-loading': isLoading }">
    <!--Cabecera Producto y Precio-->
    <div class="columns is-variable is-1 sin-bmargen is-align-items-stretch">
      <div class="column pb-0 is-two-thirds">
        <div class="box modulo">
          <div class="box mod mcabecera azul-producto">
            <div class="columns is-variable is-1 is-vcentered">
              <div class="column mctitulo">Producto</div>
              <div class="column is-two-thirds mcsubtitulo">
                KPI: Cumplimiento de precio estratégico.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- div class="column sin-pad"></div -->
      <div
        class="column is-align-self-stretch is-one-third pb-0 is-align-self-flex-end"
      >
        <!-- div class="box modulo" -->
        <div class="box mod mcabecera mctitulo azul-precio">Precio</div>
        <!-- /div -->
      </div>
    </div>
    <!--Tabla de Producto y Precio-->
    <div class="box modulo mx-0 mt-0 mb-2 mtsinradio">
      <div class="box mod mb-0 pb-1">
        <table class="table is-narrow is-fullwidth">
          <thead>
            <tr>
              <!-- <th><abbr title="Código">Código</abbr></th> -->
              <th><abbr title="Descripción">Descripción</abbr></th>
              <th>
                <abbr title="Código de Barras">Código de Barras</abbr>
              </th>
              <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th><abbr title="Banda">Banda</abbr></th>
              <th><abbr title="Más...">Más...</abbr></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prod, index) in products" :key="index">
              <!-- <th>{{ prod.code }}</th> -->
              <th>{{ prod.description }}</th>
              <th>
                <barcode
                  v-bind:value="prod.barcode"
                  height="20"
                  width="1"
                  fontSize="15"
                  :displayValue="false"
                >
                  No se puede mostrar el código de barras
                </barcode>
                <!-- <img src="assets/img/codebar.jpg" /> -->
              </th>
              <th></th>
              <th>{{ prod.price_range }}</th>
              <th class="ver-mas has-text-centered">
                <a @click="show_more(prod)"><i class="far fa-eye"></i></a>
                <div class="box modulo tooltip" v-if="prod.is_showing_more">
                  <div class="box mod has-text-centered mb-0">
                    <figure class="image is-fullwidth m-0 is-inline-block">
                      <img :src="product_image(prod.barcode)" />
                    </figure>
                  </div>
                  <div class="box mod mctitulo has-text-left mb-0 is-size-6">
                    {{ prod.description }}
                  </div>
                  <div class="box mod tooltip-desc has-text-left mb-0">
                    <p><strong>Banda: </strong>{{ prod.price_range }}</p>
                  </div>
                  <div class="box mod has-text-centered">
                    <figure class="image is-fullwidth m-0 is-inline-block">
                      <barcode
                        :value="prod.barcode"
                        class="tooltip-code"
                        height="30"
                        width="2"
                        fontSize="15"
                      >
                        No se puede mostrar el código de barras
                      </barcode>
                      <!-- <img class="tooltip-code" src="assets/img/codebar.jpg" /> -->
                    </figure>
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box mod mpie pt-1">
        <pagination
          class="is-small"
          :current="current_page"
          :total="total_products"
          :itemsPerPage="4"
          :onChange="onPaginationChanged"
        >
        </pagination>
      </div>
      <!-- <pre>
        {{ this.$props }}
      </pre> -->
    </div>
    <!-- <product-price-more
      ref="product_price_more"
      :selectedProduct="selected_product"
    /> -->
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";
// import ProductPriceMore from "./ProductPriceMore.vue";
import VueBarcode from "vue-barcode";
import Pagination from "vue-2-bulma-pagination";
import { cloneDeep } from "lodash";

export default {
  components: { /*ProductPriceMore, */ barcode: VueBarcode, Pagination },
  name: "ProductPrice",
  props: {
    brand: { type: Object, default: undefined },
    time: { type: Object, default: undefined },
  },
  data() {
    return {
      products: [],
      all_products: [],
      selected_product: undefined,
      isLoading: false,
      current_page: 1,
      total_products: 0,
    };
  },
  methods: {
    show_more(product) {
      let isShowingMore = product.is_showing_more;
      this.products.map((p) => (p.is_showing_more = false));
      product.is_showing_more = !isShowingMore;
      this.selected_product = product;
      if (product.is_showing_more) this.$emit("popup");
      // this.$refs.product_price_more.show();
    },
    onPaginationChanged(page) {
      let idx = (page - 1) * 4;
      let prds = this.all_products.slice(idx, idx + 4);
      this.products = prds;
      this.current_page = page;
    },
    product_image(barcode) {
      let path = `/productos/${barcode}.jpg`;
      var http = new XMLHttpRequest();
      http.open("HEAD", path, false);
      http.send();

      if (http.status == 404) {
        let brand_name =
          this.brand && this.brand.name ? this.brand.name.toLowerCase() : "";
        return `/productos/default_${brand_name}.jpg`;
      } else return path;
    },
    update() {
      if (this.brand && this.brand.brand_id && this.time && this.time.value) {
        let vm = this;
        vm.isLoading = true;
        post_request(vm, "/home/do-get-product-price", {
          brand: this.brand.brand_id,
          time: this.time.value,
        })
          .then((response) => {
            vm.isLoading = false;
            this.all_products = response.map((r) => {
              r.is_showing_more = false;
              return r;
            });
            let elements_number = this.all_products.length;

            let modulus = elements_number % 4;
            if (modulus > 0) {
              let left_elements = 4 - modulus;
              if (left_elements > 0) {
                let copied = this.all_products.slice(0, left_elements);
                this.all_products = this.all_products.concat(cloneDeep(copied));
              }
            }

            this.total_products = this.all_products.length;

            this.onPaginationChanged(1);
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
    hide_popups() {
      this.products.map((p) => (p.is_showing_more = false));
    },
  },
  computed: {},
  watch: {
    brand() {
      this.update();
    },
    time() {
      this.update();
    },
  },
};
</script>

<style scoped>
.box.modulo.tooltip {
  /* margin-top: 10px;*/
}
</style>
