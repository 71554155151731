<template>
  <div>
    <!-- <div class="columns barra-estado m-0 verde-agua">
      <div class="column is-2 verde-agua"></div>
      <div class="column blanco"></div>
    </div> -->
    <!--Menu Principal-->
    <div class="columns is-vcentered m-0 verde-agua">
      <!--Datos del promotor-->
      <sidebar-header />
      <!--Logo y Vigencia-->
      <div class="column is-align-self-stretch int-cabecera py-2 is-10">
        <div class="columns is-vcentered">
          <div class="column is-half has-text-left">
            <div>
              <img class="logo" src="@/assets/logo/sanofi.png" />
            </div>
          </div>
          <div class="column has-text-right is-half">
            <div id="vigencia_menu" class="dropdown">
              <!-- <calendar /> -->
              <time-dropdown
                @selected="time_selected"
                ref="time_dropdown"
                :show_all="false"
              />
            </div>
            <button
              @click="logout"
              class="button bvigencia cerrar-sesion"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
            >
              <span id="vigencia_elegida msubsec-item">Cerrar sesión</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--Sidebar y Contenido-->
    <div class="columns m-0">
      <!--Sidebar-->
      <sidebar @brand_selected="set_brand" @business_selected="set_business" />
      <!--Contenido de la interfaz-->
      <div class="column contenido-interfaz is-10">
        <div class="columns is-variable is-1">
          <!--Primera columna-->
          <div class="column is-half">
            <product-price
              :brand="selected_brand"
              :time="selected_time"
              @popup="popup('product')"
              ref="product_component"
            />
            <!--Prioridades-->
            <priorities :business="selected_business" :time="selected_time" />
            <!--Promoción-->
            <promotion
              :business="selected_business"
              :time="selected_time"
              @popup="popup('promotion')"
              ref="promotion_component"
            />
            <!--PHARMACEUTICAL-->
            <pharmaceutical :time="selected_time" />
          </div>
          <!--Fin de la primera Columna-->
          <!--Columna de Plaza-->
          <div class="column is-half">
            <!--Plaza Primaria-->
            <primary-place
              :business="selected_business"
              :brand="selected_brand"
              :time="selected_time"
            />
            <!--Plaza secundaria-->
            <secondary-place
              :business="selected_business"
              :brand="selected_brand"
              :time="selected_time"
            />
            <!--Prioridad de Ejecución-->
            <div class="box modulo mb-4">
              <div class="box mod">
                <div class="content">
                  <h5 class="mctitulo tazul mb-1">
                    PRIORIDAD DE EJECUCIÓN DE MATERIALES POP
                  </h5>
                  <p class="texto-pejecucion">
                    exh mostrador y/o tira<br />Y Marquesina y Glorificador
                    giratorio como un básico<br />Poster , floorgraphicy Tótem
                    elementos complementarios en la medida de lo
                  </p>
                </div>
              </div>
            </div>
            <!--Items NOV-->
            <pop-items
              :business="selected_business"
              :brand="selected_brand"
              :time="selected_time"
            />
          </div>
          <!--Fin de la columna de Plaza-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar";
import SidebarHeader from "@/components/SidebarHeader";
// import Calendar from "@/components/Calendar";
import Pharmaceutical from "../components/home/Pharmaceutical.vue";
import ProductPrice from "../components/home/ProductPrice.vue";
import Priorities from "../components/home/Priorities.vue";
import Promotion from "../components/home/Promotion.vue";
import PrimaryPlace from "../components/home/PrimaryPlace.vue";
import SecondaryPlace from "../components/home/SecondaryPlace.vue";
import PopItems from "../components/home/PopItems.vue";
import TimeDropdown from "../components/TimeDropdown.vue";
import moment from "moment";

export default {
  name: "Home",
  data() {
    return {
      selected_brand: undefined,
      selected_business: undefined,
      selected_time: undefined,
    };
  },
  components: {
    Sidebar,
    // Calendar,
    SidebarHeader,
    Pharmaceutical,
    ProductPrice,
    Priorities,
    Promotion,
    PrimaryPlace,
    SecondaryPlace,
    PopItems,
    TimeDropdown,
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.replace({ name: "Root" });
    },
    set_brand(brand) {
      this.selected_brand = brand;
    },
    set_business(business) {
      this.selected_business = business;
    },
    time_selected(time) {
      this.selected_time = time;
    },
    popup(type) {
      if (type == "product") {
        this.$refs.promotion_component.hide_popups();
      } else if (type == "promotion") {
        this.$refs.product_component.hide_popups();
      }
    },
  },
  mounted() {
    let currentTime = moment().format("MMYYYY");
    setTimeout(() => {
      this.$refs.time_dropdown.setSelected(currentTime);
    }, 200);
  },
};
</script>

<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>

<style>
.pagination-previous,
.pagination-next {
  display: none !important;
}
</style>
