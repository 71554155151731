<template>
  <!-- <div class="column is-2 azul-obscuro">
    <div class="block">
      <aside class="menu">
        <p class="menu-label cbautoservicios">Autoservicios</p>
        <ul class="menu-list">
          <li
            v-for="(as, idx) in autoservices"
            :key="idx"
            v-bind:class="{ 'gris-login': as == selected_drugstore }"
            @click="select_drugstore(as)"
          >
            <a>{{ as.name }}</a>
          </li>
        </ul>
        <p class="menu-label cbautoservicios">Cadena</p>
        <ul class="menu-list">
          <li
            v-for="(as, idx) in chains"
            :key="idx"
            v-bind:class="{ 'gris-login': as == selected_drugstore }"
            @click="select_drugstore(as)"
          >
            <a>{{ as.name }}</a>
          </li>
        </ul>
        <p class="menu-label">Regionales</p>
        <ul class="menu-list">
          <li
            v-for="(as, idx) in regionals"
            :key="idx"
            v-bind:class="{ 'gris-login': as == selected_drugstore }"
            @click="select_drugstore(as)"
          >
            <a>{{ as.name }}</a>
          </li>
        </ul>
        <p class="menu-label cbautoservicios">Marcas</p>
        <ul class="menu-list">
          <li
            v-for="(as, idx) in branches"
            :key="idx"
            v-bind:class="{ 'gris-login': as == selected_brand }"
            @click="select_brand(as)"
          >
            <a>{{ as.name }}</a>
          </li>
        </ul>
        <p class="menu-label">Sesión</p>
        <ul class="menu-list">
          <li><a @click="logout">Cerrar sesión</a></li>
        </ul>
      </aside>
    </div>
  </div> -->
  <div class="column pl-0 is-2 azul-obscuro">
    <div class="block menu-lateral">
      <div
        class=""
        v-for="(category, index) in business"
        :key="index"
        @click.prevent="select_category(category)"
      >
        <div class="msec mbest est-activo">{{ category.name }}</div>
        <div class="msubsec msub-activo">
          <p
            class="msubsec-item"
            v-for="(business, idx) in category.bus_businesses"
            :key="idx"
            @click.stop="select_business(business)"
            v-bind:class="{ 'mitem-activo': business == selected_business }"
            v-show="!category.is_link"
          >
            {{ business.name }}
          </p>
        </div>
      </div>
      <div class="msec mbmar mar-activo">Marcas</div>
      <div class="msubsec msub-activo">
        <p
          class="msubsec-item"
          v-for="(brand, index) in brands"
          :key="index"
          @click="select_brand(brand)"
          v-bind:class="{ 'mitem-activo': brand == selected_brand }"
        >
          {{ brand.name }}
        </p>
      </div>
      <!-- div class="msec mbest est-activo">Sesión</div>
      <div class="msubsec msub-activo">
        <p class="msubsec-item" @click="logout">Cerrar sesión</p>
      </div>
      <div class="msubsec msub-activo">
        <p class="msubsec-item" @click="admin">Admin</p>
      </div -->
    </div>
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";
import { orderBy } from "lodash";

export default {
  name: "Sidebar",
  data() {
    return {
      business: [],
      brands: [],
      selected_business: undefined,
      selected_brand: undefined,
    };
  },
  methods: {
    // logout() {
    //   this.$session.destroy();
    //   this.$router.replace({ name: "Root" });
    // },
    admin() {
      this.$router.replace({ name: "Admin" });
    },
    select_business(business) {
      this.$emit("business_selected", business);
      this.selected_business = business;
    },
    select_category(category) {
      if (category.is_link) {
        let bsns = category.bus_businesses.find((x) => x);
        if (bsns) this.select_business(bsns);
      }
    },
    select_brand(brand) {
      this.$emit("brand_selected", brand);
      this.selected_brand = brand;
    },
  },
  mounted() {},
  created() {
    let vm = this;
    post_request(vm, "/system/do-get-sidebar-items", {})
      .then((response) => {
        let bss = orderBy(response.business, ["order"], ["asc"]);
        this.brands = response.brands;

        this.business = bss;
      })
      .catch((err) => {
        console.log(err);
        error_toast(vm, err);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
