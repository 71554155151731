<template>
  <div
    class="dropdown"
    v-bind:class="{ 'is-active': isActive }"
    @click="toggle_active"
  >
    <div class="dropdown-trigger is-small">
      <button
        class="button bvigencia button-pdv"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span>{{ title_ }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu pdv" id="dropdown-menu" role="menu">
      <div class="field dropdown-item" @click.stop="sss">
        <div class="control has-icons-left">
          <input
            type="text"
            placeholder="Buscador"
            class="input is-transparent is-small"
            v-model="search"
          />
          <span class="icon is-left">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <hr class="dropdown-divider" />
      <div
        class="dropdown-content"
        v-for="(itm, idx) in shown_rows"
        :key="idx"
        v-show="itm.items.length"
      >
        <span class="dropdown-item">{{ itm.name }} </span>
        <a
          href="#"
          class="dropdown-item"
          v-for="(subitm, ix) in itm.items"
          :key="ix"
          @click="select(subitm)"
        >
          {{ subitm.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: { type: String, default: "" },
  },
  computed: {
    title_() {
      if (this.selected) return this.selected.name;
      else if (this.title) return this.title;
      else return "¿?";
    },
    shown_rows() {
      let vm = this;
      if (vm.data && vm.data.length) {
        if (!vm.search) {
          return vm.data;
        } else {
          let cln = JSON.parse(JSON.stringify(vm.data));
          let rst = cln.map((d) => {
            if (d.items && d.items.length) {
              d.items = d.items.filter((i) => {
                if (i && i.name) {
                  let isIn = i.name
                    .toLowerCase()
                    .includes(vm.search.toLowerCase());
                  return isIn;
                }
              });
            }

            return d;
          });
          return rst;
        }
      } else return [];
    },
    // iterate_items() {
    //     let vm = this;
    //   if (vm.data && vm.data.length) {
    //     return vm.data.map((d) => {
    //         if (vm.isGrouped) {
    //             vm.iterate_items.push({})
    //         }
    //     });
    //   } else return [];
    // },
  },
  data() {
    return {
      isActive: false,
      selected: undefined,
      search: "",
    };
  },
  methods: {
    toggle_active() {
      this.isActive = !this.isActive;
    },
    select(item) {
      this.selected = item;
      this.$emit("selected", item);
    },
    sss() {},
  },
  watch: {
    search() {},
  },
};
</script>
