<template>
  <div class="modal" v-bind:class="{ 'is-active': active }">
    <div class="modal-background" @click.stop="close"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Plaza Secundaria Rentas por punto de venta
        </p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body mpie pdvsmodal">
        <div class="columns">
          <div class="column" v-if="!groups.length">Sin información</div>
          <div class="column is-4" v-for="(group, index) in groups" :key="index">
            <div class="box mod mcabecera mctitulo verde-plaza">
              {{ group.name }}
            </div>
            <div class="content tabla-pdv">
              <table class="table is-hoverable">
                <thead>
                  <tr>
                    <th><abbr title="No.">No.</abbr></th>
                    <th><abbr title="Sucursal">Sucursal</abbr></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pos, idx) in group.shown_items" :key="idx">
                    <th>{{ idx + 1 }}</th>
                    <th>{{ pos.comercial_name }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="content">
              <pagination
                :current="paginations[index].current"
                :total="paginations[index].total"
                :itemsPerPage="paginations[index].itemsPerPage"
                :onChange="paginations[index].onChange"
                :key="index"
                :step="1"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- <footer class="modal-card-foot">
        <button class="button" @click="close">Cancel</button>
      </footer> -->
    </div>
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";
import { groupBy } from "lodash";
import Pagination from "vue-2-bulma-pagination";

export default {
  name: "SecondaryPlaceAll",
  components: { Pagination },
  props: {
    brand: Object,
    time: Object,
    business: Object,
  },
  data() {
    return {
      active: false,
      groups: [],
      paginations: [],
    };
  },
  methods: {
    close() {
      this.active = false;
    },
    show() {
      let vm = this;
      vm.isLoading = true;
      post_request(vm, "/home/do-get-business-rent", {
        business: this.business ? this.business.business_id : " ",
        time: this.time ? this.time.value : " ",
      })
        .then((response) => {
          vm.isLoading = false;

          let rentGrouped = groupBy(response, (x) => {
            return x.rent;
          });

          let grps = Object.keys(rentGrouped).map((k) => {
            return {
              name: k,
              items: rentGrouped[k],
              shown_items: rentGrouped[k].slice(0, 10),
            };
          });

          this.paginations = grps.map((g, gdx) => {
            return {
              current: 1,
              total: g.items.length,
              itemsPerPage: 10,
              onChange: (page) => {
                let idx = (page - 1) * 10;
                g.shown_items = g.items.slice(idx, idx + 10);
                vm.paginations[gdx].current = page;
              },
            };
          });

          this.groups = grps;
        })
        .catch((err) => {
          console.log(err);
          error_toast(vm, err);
        });
      this.active = true;
    },
    // onChange(page) {
    //   console.log(page, this);
    // },
    getRents(group, offset) {
      let lst = group.items || [];
      console.log(lst);
      return lst.slice(offset, offset + 10);
    },
  },
};
</script>
