<template>
  <div class="modal" v-bind:class="{ 'is-active': active }">
    <div class="modal-background" @click.stop="close"></div>
    <div class="modal-card">
      <header class="modal-card-head has-text-weight-semibold">
        {{ selectedItem ? selectedItem.name : "" }}
      </header>
      <section class="modal-card-body mpie has-text-centered modal-pprimaria">
        <figure class="modal-plaza-secundaria image is-fullwidth">
          <img class="image" :src="'/assets/planogramas/' + image" />
        </figure>
      </section>
    </div>
    <button
      class="modal-close is-large delete"
      aria-label="close"
      @click="close"
    ></button>
  </div>
</template>

<script>
export default {
  name: "SecondaryPlaceMore",
  props: {
    selectedItem: Object,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    image() {
      return this.selectedItem ? this.selectedItem.image : "";
    },
  },
  methods: {
    close() {
      this.active = false;
    },
    show() {
      this.active = true;
    },
  },
};
</script>
