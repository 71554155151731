<template>
  <div class="modal" v-bind:class="{ 'is-active': active }">
    <div class="modal-background" @click.stop="close"></div>
    <div class="modal-card">
      <header class="modal-card-head has-text-weight-semibold">
        <!-- titulo -->
      </header>
      <section class="modal-card-body mpie has-text-centered modal-pprimaria">
        <figure class="image is-fullwidth">
          <img class="image" :src="api_host + '/' + fl_pth" />
        </figure>
        <!-- <pre>{{ selectedItem }}</pre>
        <pre>{{ fl_pth }}</pre> -->
      </section>
      <!-- <footer class="modal-card-foot">
        <button class="button" @click="close">Cancel</button>
      </footer> -->
    </div>
    <button
      class="modal-close is-large delete"
      aria-label="close"
      @click="close"
    ></button>
  </div>
</template>

<script>
import { api_host } from "@/utils";

export default {
  name: "PrimaryPlaceMore",
  props: {
    selectedItem: { type: Object, default: undefined },
  },
  data() {
    return {
      active: false,
      api_host: api_host,
    };
  },
  computed: {
    fl_pth() {
      return this.selectedItem && this.selectedItem.relative_path
        ? this.selectedItem.relative_path
        : "";
    },
  },
  methods: {
    close() {
      this.active = false;
    },
    show() {
      this.active = true;
      console.log(this.selectedItem);
    },
  },
};
</script>
