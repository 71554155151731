<template>
  <div class="box modulo">
    <div class="box mod mcabecera marron-pharma mctitulo">PHARMACEUTICAL</div>
    <div class="box mod mcontenido">
      <div class="content">
        <p class="pharma-texto"><i class="fas fa-info-circle tazul pr-1"></i> &nbsp;<span v-html="content"></span> </p>
      </div>
    </div>
  </div>
</template>

<script>
import { post_request, error_toast } from "@/utils";

export default {
  name: "Pharmaceutical",
  props: {
    time: { type: Object, default: undefined },
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    update() {
      if (this.time && this.time.value) {
        let vm = this;
        post_request(vm, "/home/do-get-pharmaceutical", { time: vm.time.value })
          .then((response) => {
            this.content = response;
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
  },
  created() {
    this.update();
  },
  watch: {
    time() {
      this.update();
    },
  },
};
</script>
