<template>
  <div class="column is-2 verde-agua">
    <div class="block">
      <div class="columns is-vcentered">
        <div class="column is-one-fifth solo-lpad">
          <figure class="image is-fullwidth is-inline-block">
            <img src="assets/img/user/user.png" alt="Image" />
          </figure>
        </div>
        <div class="column">
          <div class="content datos-promotor">
            {{ name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      role: "",
    };
  },
  created() {
    let user = this.$session.get("user");
    if (user) {
      this.name = user.name;
      this.role = user.role.name;
    }
  },
};
</script>
