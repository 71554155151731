<template>
  <div>
    <div class="box modulo mb-2" v-bind:class="{ 'is-loading': isLoading }">
      <div class="box mod mcabecera mctitulo verde-plaza">PLAZA PRIMARIA</div>
      <!-- section class="section" -->
      <div class="box modulo int-mod mcontenido">
        <div class="owl-carousel owl-theme">
          <figure
            class="image is-fullwidth"
            v-for="(file, index) in products"
            :key="index"
          >
            <img
              class="img-primaria"
              @click="show_more(file)"
              :src="api_host + '/' + file.relative_path"
            />
          </figure>
        </div>
        <!-- Start Carousel -->
        <!-- <div id="carousel-demo" ref="carousel" class="carousel">
            <img
              v-for="(file, index) in products"
              :key="index"
              class="image"
              @click="show_more(file)"
              :src="api_host + '/' + file.file_path.relative_path"
            />
          </div>           -->
        <!-- End Carousel -->
      </div>
      <!-- /section -->
      <!-- div class="box modulo int-mod mcontenido" -->
      <!-- <figure class="image is-fullwidth">
          <img
          
            class="img-primaria"
            src="assets/img/EXHIBIDOR/exhibidor-primario-1.png"
          />
        </figure> -->
      <!-- /div -->
    </div>
    <!--Espacio lineal-->
    <div class="box modulo mb-2">
      <div class="box mod mprincipal rojo-elineal">
        {{ text }}
      </div>
    </div>
    <!-- <pre>{{ this.$props }}</pre> -->
    <primary-place-more
      ref="primary_place_more"
      :selectedItem="selected_item"
    />
  </div>
</template>

<script>
import { post_request, error_toast, api_host } from "@/utils";
import PrimaryPlaceMore from "./PrimaryPlaceMore.vue";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
// import bulmaCarousel from "bulma-extensions/bulma-carousel/dist/js/bulma-carousel";

export default {
  name: "PrimaryPlace",
  components: { PrimaryPlaceMore },
  data() {
    return {
      place: {},
      products: [],
      text: "",
      isLoading: false,
      carousel: undefined,
      selected_item: undefined,
      api_host: api_host,
    };
  },
  props: {
    business: { type: Object, default: undefined },
    brand: { type: Object, default: undefined },
    time: { type: Object, default: undefined },
  },
  mounted() {},
  watch: {
    time() {
      this.update();
    },
    brand() {
      this.update();
    },
    business() {
      this.build();
    },
  },
  methods: {
    show_more(file) {
      this.selected_item = file;
      this.$refs.primary_place_more.show();
    },
    build() {
      let vm = this;
      vm.products = [];
      window.jQuery(".owl-carousel").owlCarousel("destroy");
      if (vm.brand && vm.business && vm.place && vm.place.place_id) {
        var selected = "";
        if ([110, 112, 113].includes(vm.business.type_id))
          selected = "drugstores";
        else if ([111].includes(vm.business.type_id)) selected = "autoservices";
        if (selected) {
          switch (selected) {
            case "autoservices":
              vm.products = vm.place.setup.autoservices || [];
              vm.text = vm.place.setup ? vm.place.setup.autoservice_text : "";
              break;
            case "drugstores":
              vm.products = vm.place.setup.drugstores || [];
              vm.text = vm.place.setup ? vm.place.setup.drugstore_text : "";
              break;
            default:
              break;
          }
          setTimeout(() => {
            window.jQuery(".owl-carousel").owlCarousel({
              items: 1,
              loop: true,
              margin: 0,
              nav: false,
            });
          }, 500);
        }
      }
    },
    update() {
      let vm = this;
      if (vm.time && vm.time.value && vm.brand && vm.brand.brand_id) {
        vm.isLoading = true;
        post_request(vm, "/home/do-get-primary-place", {
          brand: vm.brand.brand_id,
          time: vm.time.value,
        })
          .then((response) => {
            vm.isLoading = false;
            vm.place = response;
            vm.build();
            // this.products = response;

            // // To access to bulmaCarousel instance of an element
            // const element = document.querySelector("#my-element");
            // if (element && element.bulmaCarousel) {
            //   // bulmaCarousel instance is available as element.bulmaCarousel
            // }
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
  },
};
</script>

<style>
@import "~bulma-extensions/bulma-carousel/dist/css/bulma-carousel.min.css";
</style>
